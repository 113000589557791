import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DataRole } from '@/services/firebase/functions/getStoredUser';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import i18n from '@/services/i18next/i18nextConfig';

export const useDataRole = () => {
  const { dataRole } = useParams();
  const { dataRoleLookup } = useUserDataStore();

  const result = useMemo(() => {
    if (!dataRoleLookup)
      return {
        error: true,
        dataRole: '',
        dataRoleUrlParam: '',
        examplePrompts: [] as string[],
        language: '',
      };

    const currentDataRole =
      dataRole && dataRoleLookup[dataRole]
        ? dataRoleLookup[dataRole]
        : (Object.values(dataRoleLookup)[0] as DataRole);

    return {
      error: !currentDataRole,
      dataRoleUrlParam: currentDataRole?.urlParam || (currentDataRole?.roleName as string),
      dataRole: currentDataRole?.roleName as string,
      language: currentDataRole?.language,
      examplePrompts: currentDataRole?.examplePrompts || [],
    };
  }, [dataRole, dataRoleLookup]);

  useEffect(() => {
    if (result.language) {
      i18n.changeLanguage(result.language);
    }
  }, [result.language]);

  return result;
};
