import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { LoadingCover } from '../../Common/Loading/LoadingCover';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import { useDataRole } from '@/features/Search/state/hooks/useDataRole';

const TIMEOUT_MS = 15000;

type AuthRedirectProps = {
  onLoggedIn?: string;
  onLoggedOut?: string;
  onEmailNotVerified?: string;
  requireDataRole?: boolean;
  children?: React.ReactNode;
};
export const AuthRedirect = ({
  children,
  onLoggedIn,
  onLoggedOut,
  onEmailNotVerified,
  requireDataRole = false,
}: AuthRedirectProps) => {
  const location = useLocation();
  const auth = getAuth();
  const { isInitialized } = useUserDataStore();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { error } = useDataRole();
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (loading) {
      timeoutId = setTimeout(() => {
        if (loading) {
          setTimedOut(true);
        }
      }, TIMEOUT_MS);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [loading]);

  useEffect(() => {
    if (!auth.currentUser && isInitialized) {
      // setLoading(false);
      return;
    }
    if (!isInitialized || (error && requireDataRole)) return;
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, isInitialized, error, requireDataRole]);


  useEffect(() => {
    (window as any).saltfishConfig = {
      customerId: 'cura-ai',
      uid: user?.uid,
    };
  }, [user]);

  if (!loading) {
    if (user && !user.emailVerified && onEmailNotVerified) {
      return <Navigate to={onEmailNotVerified} replace state={{ from: location }} />;
    }
    if (user && onLoggedIn) {
      return <Navigate to={onLoggedIn} replace state={{ from: location }} />;
    }
    if (!user && onLoggedOut) {
      return <Navigate to={onLoggedOut} replace state={{ from: location }} />;
    }
  }

  if (timedOut) {
    return <Navigate to={'/error'} replace state={{ from: location }} />;
  }

  return <LoadingCover isLoading={loading}>{children}</LoadingCover>;
};
